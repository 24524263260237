<template>
  <section class="c-worldmap">
    <l-map :useGlobalLeaflet="true" @ready="onMapReady" v-model:zoom="zoom" :center="center" :options="options" @update:center="centerUpdated">
      <l-tile-layer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png" layer-type="base" name="OpenStreetMap"></l-tile-layer>
      <l-control-zoom position="bottomright"></l-control-zoom>
      <l-marker-cluster-group :showCoverageOnHover="false" :maxClusterRadius="100" v-if="selectedMarkers.length">
        <l-marker :icon="getIcon(marker)" :lat-lng="marker.position" v-for="marker in selectedMarkers" :key="marker.id">
          <l-popup :options="popup_options">
            <div class="c-worldmap__item">
              <h2 class="c-worldmap__item-title" v-html="marker.nom"></h2>
              <p class="c-worldmap__item-address" v-html="marker.adresse"></p>
              <div class="c-worldmap__item-tags">
                <a class="c-worldmap__item-tag c-worldmap__item-tag--shoes" v-if="marker.reparationChaussures"></a>
                <a class="c-worldmap__item-tag c-worldmap__item-tag--clothes" v-if="marker.reparationTextiles"></a>
                <a class="c-worldmap__item-tag c-worldmap__item-tag--mailbox" v-if="marker.correspondance"></a>
                <a class="c-worldmap__item-tag c-worldmap__item-tag--store" v-if="marker.displayAdresse"></a>
              </div>
              <div class="c-worldmap__item-actions">
                <a class="c-worldmap__item-action c-worldmap__item-action--phone" :href="'tel:'+marker.telephone" v-if="marker.telephone.length">{{ $t('store.call') }}</a>
                <a class="c-worldmap__item-action c-worldmap__item-action--mail" :href="'mailto:'+marker.email" v-if="marker.email.length">{{ $t('store.write') }}</a>
                <a class="c-worldmap__item-action c-worldmap__item-action--gps" @click="openNavigationSelector(marker)">{{ $t('store.go') }}</a>
              </div>
              <router-link class="c-worldmap__item-more"  :to="{name:'store', params:{id:marker.id}}" @click="closePopup">{{ $t('map.more') }}</router-link>
            </div>
          </l-popup>
        </l-marker>
      </l-marker-cluster-group>
      <div class="c-worldmap__gps" v-if="hasGeolocation">
        <a class="c-worldmap__gps-button" @click="detectPosition"></a>
        <div class="c-worldmap__gps-info">{{ $t('map.gps') }}</div>
      </div>
    </l-map>
  </section>
</template>

<script>

import "leaflet/dist/leaflet.css";
import 'vue-leaflet-markercluster/dist/style.css'

import L from 'leaflet'
window.L = L

import { LMap, LTileLayer, LControlZoom, LMarker, LPopup } from "@vue-leaflet/vue-leaflet";
import { LMarkerClusterGroup } from 'vue-leaflet-markercluster'
import { icon } from "leaflet";

import shoes from "../assets/marker/shoes.svg";
import shoes_active from "../assets/marker/shoes_active.svg";
import clothes from "../assets/marker/clothes.svg";
import clothes_active from "../assets/marker/clothes_active.svg";
import both from "../assets/marker/both.svg";
import both_active from "../assets/marker/both_active.svg";

export default {
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LPopup,
    LMarkerClusterGroup
  },
  data() {
    return {
      instance: false,
      zoom: 12,
      loading: false,
      hasGeolocation: "geolocation" in navigator,
      center: {lat:48.866667, lng:2.333333},
      popup_options:{
        maxWidth:320,
        offset:[0,0],
        autoPanPaddingTopLeft:[0,100]
      },
      options:{
        zoomControl: false,
        minZoom:6,
        maxBoundsViscosity:0.9,
        maxBounds: [
          [41.314, -5.142],
          [51.124, 9.662]
        ]
      },
      filters:{
        activities:[],
        filters:[]
      },
      icons:{
        shoes: icon({
          iconUrl: shoes,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        shoes_active: icon({
          iconUrl: shoes_active,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        clothes: icon({
          iconUrl: clothes,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        clothes_active: icon({
          iconUrl: clothes_active,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        both: icon({
          iconUrl: both,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        }),
        both_active: icon({
          iconUrl: both_active,
          iconSize: [44, 57],
          iconAnchor: [44, 57]
        })
      }
    }
  },
  computed:{
    markers(){
      return this.$store.state.markers;
    },
    id(){
      return this.$route.params.id
    },
    selectedMarkers(){

      if( !this.filters.activities.length && !this.filters.filters.length ){

        return Object.values(this.markers)
      }
      else{

        let match = {
          shoes: 'reparationChaussures',
          clothes: 'reparationTextiles',
          stores: 'displayAdresse',
          services: 'serviceDomicile',
          change: 'correspondance'
        }

        return Object.values(this.markers).filter(marker=>{
          return this.filters.activities.every(key => marker[match[key]] === true) && this.filters.filters.every(key => marker[match[key]] === true);
        })
      }
    }
  },
  methods:{
    onMapReady(instance) {
      this.instance = instance;
    },
    openNavigationSelector(marker){
      this.emitter.emit('openNavigationSelector', marker)
    },
    closePopup(){
      this.instance.closePopup();
    },
    detectPosition(){
      navigator.geolocation.getCurrentPosition((position) => {

        this.center = {lat:position.coords.latitude, lng:position.coords.longitude};

      }, (error)=>{

        alert(error.message);
      });
    },
    getIcon(marker){

      if( marker.reparationChaussures && marker.reparationTextiles )
        return this.id===marker.id?this.icons.both_active:this.icons.both
      else if( marker.reparationChaussures )
        return this.id===marker.id?this.icons.shoes_active:this.icons.shoes
      else
        return this.id===marker.id?this.icons.clothes_active:this.icons.clothes
    },
    centerUpdated(center){

        this.center = center;
    }
  },
  mounted() {
    this.emitter.on("goTo", address => {
      this.center = {lat:address.latLng[0], lng:address.latLng[1]};
    });

    this.emitter.on("filtersUpdated", filters => {
      this.filters = filters;
    });
  }
}
</script>

<style lang="scss">
.c-worldmap{
  width: 100%; height: 100%;
  .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
    box-shadow: $box-shadow; border: 0;
  }
  .leaflet-control-zoom-in, .leaflet-control-zoom-out{
    background: $c-white no-repeat center; background-size: 60%;
    span{ display: none }
  }
  .leaflet-control-zoom-in{ background-image: url("../assets/picto/plus.svg") }
  .leaflet-control-zoom-out{ background-image: url("../assets/picto/minus.svg") }
  &__gps{
    &-button{
      position: absolute; right: $space-m; bottom: calc($space + 84px); width: 30px; height: 30px; z-index: 9999;
      background: url("../assets/picto/gps.svg") no-repeat center #fff; border-radius: 4px;
      box-sizing: initial; cursor: pointer; background-size: 60%;
      box-shadow: $box-shadow;
      &:hover{ background-color:#f4f4f4 }
    }
    &-info{
      position: absolute; right: calc($space-m + 50px); bottom: calc($space-m + 10px); width: 42rem; height: 90px; z-index: 9999; opacity: 0;
      background: url("../assets/picto/gps.svg") no-repeat 90% 50% #fff; background-size: 3rem; border-radius: 1rem; pointer-events: none;
      font-size: $font-m; padding: 0 4rem; display: flex; align-items: center; text-align: right; padding-right: 10rem;
    }
    &:hover &-info{ opacity: 1 }
  }
  &__item{
    width: 32rem; background: $c-background; padding: $space; border-radius: $space; box-shadow: $box-shadow; display: flex;
    flex-flow: column;
    &-title{ @extend %title-main; padding-right: 25px }
    &-actions{ display: flex; grid-gap: $space-s; border-top: 1px solid $c-border; padding-top: $space; margin: $space 0 }
    &-tags{ display: flex; grid-gap: $space-s }
    &-tag{
      display: inline-block; width: 3.5rem; height: 3.5rem; border-radius: 50%; background: no-repeat center $c-white; background-size: 60%;
      &--clothes{ background-image: url("../assets/picto/clothes.svg"); background-color: $c-theme-alt }
      &--shoes{ background-image: url("../assets/picto/shoes.svg"); background-color: $c-theme }
      &--store{ background-image: url("../assets/picto/store.svg") }
      &--mailbox{ background-image: url("../assets/picto/mailbox.svg") }
    }
    &-action{
      background: $c-white; padding: $space-xs; padding-right: $space; border-radius: $radius; flex-shrink: 0; grid-gap: $space-xs;
      font-size: $font-xs; font-weight: 500; cursor: pointer; transition: allow(box-shadow); display: inline-flex; align-items: center;
      &:hover{ box-shadow: $box-shadow }
      &:before{
        display: inline-block; width: 1.6rem; height: 1.6rem; flex-shrink: 0;
        background: no-repeat center; background-size: contain; content: '';
      }
      &--gps:before{ background-image: url("../assets/picto/pin.svg") }
      &--phone:before{ background-image: url("../assets/picto/phone.svg") }
      &--mail:before{ background-image: url("../assets/picto/mail.svg") }
    }
    &-more{ font-size: $font-xs; text-decoration: underline; margin: 0 auto }
  }

  .marker-cluster{
    background: $c-white; border: 1px solid; border-radius: 50%; display: inline-flex; align-items: center;
    justify-content: center; font-size: $font-xs; font-weight: 500;
    &-medium{
      width: 80px!important; height: 80px!important; margin-left: -40px!important; margin-top: -40px!important;
      font-size: $font-s; font-weight: 600;
    }
  }

  .leaflet{
    &-container a.leaflet-popup-close-button{
      background: url("../assets/picto/close.svg") no-repeat center; display: inline-block; width: 2.4rem; height: 2.4rem;
      right: $space; top: $space; background-size: contain; cursor: pointer;
      span{ display: none }
    }
    &-control-zoom{ margin-right: $space-m; margin-bottom: $space }
    &-control-attribution{ font-size: $font-xs; padding: 1px 10px; background: rgba(255,255,255,0.5); backdrop-filter: blur(2px) }
    &-attribution-flag{ display: none!important }
    &-popup{ margin-bottom: 77px; margin-left: -20px }
    &-popup-tip{ background: $c-background; box-shadow: $box-shadow }
    &-popup-content, &-container{ font-size: inherit; line-height: inherit; font-family: inherit; margin: 0; width: auto!important }
    &-popup-content-wrapper, .leaflet-popup-tip{ background: none; box-shadow: none }
    &-container a{ color: inherit }
  }

}
</style>
