// scripts/api.js
import axios from 'axios';
import md5 from 'md5';

const api_url = process.env.VUE_APP_API_URL + '/api/public/carte/geoloc';

const search = async (query, limit) => {

  if (query.replace(/ /g, '').length < 3) return [];

  const params = {
    query: query,
    limit: limit
  };

  try {
    const res = await axios.get(api_url, { params: params });
    let result = [];

    res.data.forEach(feature => {
      let id = feature.id ? feature.id : md5(feature.label);
      result.push({ id: id, type: feature.type, label: feature.label, latLng: [feature.latitude, feature.longitude] });
    });

    return result;

  } catch (error) {

    console.error('Error fetching address autocomplete data:', error);
    return [];
  }
};

export {search}