import { createStore } from 'vuex'

// Create a new store instance.
const store = createStore({
    state () {
        return {
            markers: {},
            search_results: [],
            recent_search: []
        }
    },
    mutations: {
        markers (state, markers) {

            state.markers = markers
        },
        search_results (state, search_results) {

            state.search_results = search_results
        },
        recent_search(state, recent_search) {

            if( !state.recent_search.some(obj => obj.id === recent_search.id) ){

                state.recent_search.unshift(recent_search);

                if (state.recent_search.length > 10)
                    state.recent_search.pop();
            }

            localStorage.setItem('search', JSON.stringify(state.recent_search));
        },
    },
    actions:{
        recent_search({ state }) {

            let recent_search = localStorage.getItem('search');

            if( recent_search && recent_search.length )
                state.recent_search = JSON.parse(recent_search);
        }
    }
})

export default store