<template>
  <WorldMap/>
  <TopBar/>
  <SideBar/>
  <NavigationSelector/>
</template>

<script>

import Bowser from "bowser";
const browser = Bowser.getParser(window.navigator.userAgent);

import WorldMap from './components/WorldMap.vue'
import TopBar from "@/components/TopBar.vue";
import SideBar from "@/components/SideBar.vue";
import {compress, decompress} from "compress-json";
import NavigationSelector from "@/components/NavigationSelector.vue";

export default {
  name: 'App',
  components: {
    NavigationSelector,
    SideBar,
    TopBar,
    WorldMap
  },
  data() {
    return {
      version: 1,
      api_url: process.env.VUE_APP_API_URL+'/api/public/carte/all',
      loading: false,
    }
  },
  methods:{
    addBrowserClasses(){

      if( !browser.satisfies({"internet explorer": ">11", safari: '>=13', chrome: ">=85", firefox: ">=83", edge: ">=84"}) ){

        document.body.classList.add('unsupported-browser')
      }
      else{

        document.body.classList.add(browser.getPlatformType(true))
        document.body.classList.add(browser.getOSName(true))
      }
    },
    getMarkers(){

      if( this.loading )
        return;

      let data =  localStorage.getItem('data')

      if( data ){

        data = JSON.parse( data );

        if( data.version === this.version )
          this.$store.commit('markers', decompress(data.markers))
      }

      this.loading = true;

      this.$http.get(this.api_url).then((res) => {

        const markers = res.data.map(obj => {
          if (obj.serviceDomicile === "NON") {
            return { ...obj, serviceDomicile: false };
          }
          return obj;

        }).reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {});

        let compressed_markers = compress(markers)
        localStorage.setItem('data', JSON.stringify({version:1, timestamp:Date.now(), markers:compressed_markers}))

        this.loading = false
        this.$store.commit('markers', markers)
      })
    }
  },
  mounted(){

    document.body.classList.remove('loading');
    document.body.classList.add('loaded');

    this.getMarkers();
  },
  created() {

    this.addBrowserClasses();
  }
}
</script>

<style lang="scss">

@import "styles/reset";

/*
* Core
*/

@import "styles/core/animations";
@import "styles/core/grid";
@import "styles/core/gutemberg";
@import "styles/core/has-sizer";
@import "styles/core/layout";
@import "styles/core/responsive";
@import "styles/core/text";
@import "styles/core/theme";
@import "styles/core/unsupported-browsers";

/*
* Misc
*/

@import "styles/misc/fonts";
@import "styles/misc/keyframes";
@import "styles/misc/media-queries";

/*
* Vendors
*/

@import "styles/vendor/vuejs";

</style>
