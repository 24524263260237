<template>
  <aside class="c-sidebar" :class="{'is-active':active}">
    <div class="c-sidebar__inner">
      <router-view v-slot="{ Component }">
        <transition :name="direction">
          <component :is="Component" :key="$route.params.id"/>
        </transition>
      </router-view>
    </div>
  </aside>
</template>

<script>

export default {
  data() {
    return{
      active: false,
      current: false,
      id: false,
      direction: 'left'
    }
  },
  watch:{
    $route(to, from){
      this.direction = to.path === '/' || from.path === '/' ? 'wait' : ( to.meta.depth < from.meta.depth ? 'slide-right': 'slide-left' )
      this.active = to.path !== '/'
    }
  },
  methods:{
    hide(){
      this.active = false
    },
    show(data){
      if( this.current === data.element && this.active ){

        this.hide()
      }
      else{

        if( 'id' in data )
          this.id = data.id

        this.current = data.element
        this.active = true
      }
    }
  },
  mounted() {
    this.emitter.on('closeSidebar', this.hide);
    this.emitter.on('showSidebar', this.show);
  }
}
</script>

<style lang="scss">
.c-sidebar{
  position: absolute; left: $space-m; bottom: $space; top: 8rem; width: 36rem; z-index: 9998; background: $c-background;
  border-radius: $space; box-shadow: $box-shadow; overflow: hidden;
  transition: allow(transform, $animation-duration); transform: translateX(calc(-100% - $space-l));
  &.is-active{ transform: none }
  &__inner{
    height: 100%; overflow: auto; width: 100%; overflow-x: hidden; position: relative;
    @include scrollbar(4px, rgba(0,0,0,0.05), rgba(0,0,0,0), 4px, 4px);
    &:hover{
      @include scrollbar(4px, rgba(0,0,0,0.2), rgba(0,0,0,0), 4px, 4px)
    }
    >*{ padding: $space; padding-right: 16px; height: 100% }
  }
}
</style>
