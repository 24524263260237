<template>
  <div class="c-search-results">
    <router-link class="c-search-results__close" :to="{name:'home'}"></router-link>
    <h2 class="c-search-results__title">{{ $t('search.title') }}</h2>
    <div class="c-search-results__items" v-if="search_results.length">
      <router-link class="c-search-results__online" :to="{name:'online-solutions'}">
        <span v-html="$t('search.online')"></span>
        <span class="c-search-results__online-more" @click="showItem">{{ $t('online.see') }}</span>
      </router-link>
      <StoreSummary :order="index" v-for="(item, index) in search_results" :marker="getMarker(item.id)" :key="item.id"/>
    </div>
    <div class="c-search-results__no-results" v-else>
      <div class="c-search-results__message">{{ $t('search.error') }}</div>
      <router-link class="c-search-results__cta c-search-results__cta--online" :to="{name:'online-solutions'}">{{ $t('search.see_online') }}</router-link>
      <a class="c-search-results__cta c-search-results__cta--search" @click="editSearch">{{ $t('search.edit') }}</a>
    </div>
    </div>
</template>

<script>
import StoreSummary from "@/components/StoreSummary.vue";

export default {
  components: {
    StoreSummary
  },
  computed:{
    search_results(){
      return this.$store.state.search_results.filter((item)=>{
        return item.type === "BOUTIQUES" && item.id && item.id in this.markers
      });
    },
    markers(){
      return this.$store.state.markers;
    }
  },
  methods:{
    getMarker(id){
      return this.markers[id]
    },
    editSearch(){
      this.emitter.emit('searchFocus');
      this.$router.push({name:'home'})
    }
  }
}
</script>

<style lang="scss">
.c-search-results{
  display: flex; flex-flow: column; grid-gap: $space;
  &__online{
    background: $c-black; color: $c-white; border-radius: $space; padding: $space; display: flex; grid-gap: $space;
    align-items: center; order: 3; cursor: pointer;
    &-more{
      display: inline-flex; align-items: center; grid-gap: $space-s; font-weight: 600;
      &:after{
        background: url("../assets/picto/arrow-white.svg") no-repeat center; background-size: cover; content: ''; width: 1em;
        height: 1em; display: inline-block;
      }
    }
  }
  &__items{
    display: flex; flex-flow: column; grid-gap: $space; padding-top: $space;
  }
  .c-store-summary:nth-child(4){ border-top: 0; padding-top: 0 }
  &__title{ @extend %title-section }
  &__cta{
    padding: $space; border-radius: $radius; background: $c-white; text-align: center; font-weight: 600;
    display: flex; align-items: center; grid-gap: $space-s; justify-content: center; cursor: pointer;
    &--online{ background: $c-black; color: $c-white; margin-bottom: $space-s }
    &--search{
      &:before{
        content: ''; width: 2rem; height: 2rem; background: url("../assets/picto/magnifier.svg") no-repeat center;
        background-size: contain; display: inline-block; margin-right: $space-s
      }
    }
  }
  &__message{
    text-align: center; margin: $space 0; line-height: 1.6; padding: 0 $space; display: flex; flex-flow: column;
    align-items: center;
    &:before{
      content: ''; width: 6rem; height: 6rem; background: url("../assets/picto/smiley.svg") no-repeat center;
      background-size: contain; display: inline-block; margin-bottom: $space
    }
  }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center; display: inline-block; width: 3.2rem; height: 3.2rem;
    position: absolute; right: $space; top: $space; background-size: contain; cursor: pointer;
  }
}
</style>
