<template>
  <article class="c-store-details">
    <a class="c-store-details__back" @click="back">{{ $t('store.back') }}</a>
    <div class="c-store-details__content" v-if="marker">
      <h2 class="c-store-details__title" v-html="marker.nom"></h2>
      <div class="c-store-details__tags" v-drag>
        <a class="c-store-details__tag c-store-details__tag--shoes" v-if="marker.reparationChaussures">{{ $t('store.shoes') }}</a>
        <a class="c-store-details__tag c-store-details__tag--clothes" v-if="marker.reparationTextiles">{{ $t('store.clothes') }}</a>
        <a class="c-store-details__tag c-store-details__tag--mailbox" v-if="marker.correspondance">{{ $t('store.change') }}</a>
        <a class="c-store-details__tag c-store-details__tag--store" v-if="marker.displayAdresse">{{ $t('store.store') }}</a>
        <a class="c-store-details__tag c-store-details__tag--service" v-if="marker.serviceDomicile">{{ $t('store.service') }}</a>
      </div>
      <div class="c-store-details__data">
        <div class="c-store-details__info c-store-details__info--gps">
          <span></span>
          <p>
            {{ marker.adresse }}<br/>
            {{ marker.codePostal }} {{ marker.ville }}
          </p>
          <a @click="openNavigationSelector">{{ $t('store.go') }}</a>
        </div>
        <div class="c-store-details__info c-store-details__info--phone" v-if="marker.telephone.length">
          <span></span>
          <p>{{ marker.telephone }}</p>
          <a :href="'tel:'+marker.telephone">{{ $t('store.call') }}</a>
        </div>
        <div class="c-store-details__info c-store-details__info--mail" v-if="marker.email.length">
          <span></span>
          <p>{{ marker.email }}</p>
          <a :href="'mailto:'+marker.email">{{ $t('store.write') }}</a>
        </div>
        <div class="c-store-details__info c-store-details__info--website" v-if="marker.displaySiteInternet">
          <span></span>
          <p>{{ marker.siteInternet.replace('https://','') }}</p>
          <a :href="marker.siteInternet" target="_blank">{{ $t('store.see') }}</a>
        </div>
      </div>
      <div class="c-store-details__brands">
        <div class="c-store-details__brands-title">{{ $t('store.brands') }}</div>
        <div class="c-store-details__brands-logos">
          <img src="../assets/brands/zara.png"/>
          <img src="../assets/brands/zara.png"/>
          <img src="../assets/brands/zara.png"/>
        </div>
      </div>
      <div class="c-store-details__actions">
        <a class="c-store-details__cta c-store-details__cta--locate" @click="locate" v-if="marker.displayAdresse">{{ $t('store.locate') }}</a>
        <a class="c-store-details__cta c-store-details__cta--report">{{ $t('store.report') }}</a>
      </div>
    </div>
    <div class="c-store-details__content c-store-details__content--404" v-else>
      <h2 class="c-store-details__title">{{ $t('store.error') }}</h2>
      <p>{{ $t('store.not_found') }}</p>
    </div>
  </article>
</template>

<script>
export default {
  methods:{
    back(){
      this.$router.go(-1)
    },
    locate(){
      this.emitter.emit('goTo', {latLng:this.marker.position})
    },
    openNavigationSelector(){
      this.emitter.emit('openNavigationSelector', this.marker)
    }
  },
  computed:{
    markers(){
      return this.$store.state.markers;
    },
    id(){
      return this.$route.params.id
    },
    marker(){
      return this.id in this.markers ? this.markers[this.id] : false
    }
  }
}
</script>

<style lang="scss">
.c-store-details{
  display: flex; flex-flow: column; grid-gap: $space; position: relative;
  &__title{ @extend %title-section }
  &__content{
    display: flex; flex-flow: column; grid-gap: $space-m; height: 100%;
    &--404{ align-items: center; justify-content: center; grid-gap: $space-s }
  }
  &__back{
    display: inline-flex; align-items: center; grid-gap: $space-s; cursor: pointer;
    &:before{
      content: ''; display: inline-block; width: 4rem; height: 4rem; transform: rotate(180deg); border-radius: 50%;
      background: url("../assets/picto/arrow.svg") no-repeat center $c-white; background-size: 60%;
    }
  }
  &__tags{
    display: flex; grid-gap: $space; overflow: hidden; flex-shrink: 0;
    margin-left: -$space; padding-left: $space; width: calc(100% + 2*$space); padding-right: $space; user-select: none;
  }
  &__tag{
    display: flex; align-items: center;; grid-gap: $space-s; white-space: nowrap;
    &:before{
      content: ''; display: inline-block; width: 5rem; height: 5rem; border-radius: 50%;
      background: no-repeat center $c-white; background-size: 60%;
    }
    &--clothes:before{ background-image: url("../assets/picto/clothes.svg"); background-color: $c-theme-alt }
    &--shoes:before{ background-image: url("../assets/picto/shoes.svg"); background-color: $c-theme }
    &--store:before{ background-image: url("../assets/picto/store.svg") }
    &--mailbox:before{ background-image: url("../assets/picto/mailbox.svg") }
    &--service:before{ background-image: url("../assets/picto/service.svg") }
  }
  &__data{
    border-top: 1px solid $c-border; padding-top: $space; display: flex; flex-flow: column; grid-gap: $space;
    border-bottom: 1px solid $c-border; padding-bottom: $space;
  }
  &__info{
    display: flex; grid-gap: $space; align-items: flex-start;
    p{ padding-top: 4px; flex: 1; overflow: hidden; text-overflow: ellipsis }
    span{
      display: inline-block; width: 2.4rem; height: 2.4rem; flex-shrink: 0;
      background: no-repeat center; background-size: contain;
    }
    a{
      background: $c-white; padding: $space-xxs $space; border-radius: $radius; margin-left: auto; flex-shrink: 0;
      font-size: $font-xs; font-weight: 500; cursor: pointer; transition: allow(box-shadow);
      &:hover{ box-shadow: $box-shadow }
    }
    &--gps span{ background-image: url("../assets/picto/pin.svg") }
    &--phone span{ background-image: url("../assets/picto/phone.svg") }
    &--mail span{ background-image: url("../assets/picto/mail.svg") }
    &--website span{ background-image: url("../assets/picto/globe.svg") }
    &--website span{ background-image: url("../assets/picto/globe.svg") }
  }
  &__brands{
    display: flex; flex-flow: column; grid-gap: $space;
    &-title{ font-size: $font-xs; color: $c-grey }
    &-logos{
      display: flex; grid-gap: $space; overflow: hidden; margin-left: -$space; padding-left: $space;
      width: calc(100% + 2*$space); padding-right: $space; overflow: hidden;
      img{ height: 3rem }
    }
  }
  &__actions{
    display: flex; flex-flow: column; grid-gap: $space-s; margin-top: auto; padding-bottom: 4px;
  }
  &__cta{
    display: flex; align-items: center; border-radius: $radius; padding: 15px $space; background: $c-white;
    justify-content: center; font-weight: 600; grid-gap: $space-s; cursor: pointer;
    &:before{ content: ''; display: inline-block; width: 2rem; height: 2rem; background: no-repeat center; background-size: contain }
    &--locate{
      background: $c-black; color: $c-white;
      transition: allow(background);
      &:hover{ background: #222 }
      &:before{ background-image: url("../assets/picto/pin-white.svg") }
    }
    &--report{
      transition: allow(box-shadow);
      &:hover{ box-shadow: $box-shadow }
      &:before{ background-image: url("../assets/picto/report.svg") }
    }
  }
}
</style>
