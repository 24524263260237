<template>
  <transition name="fade">
    <section class="c-navigation-selector" v-if="opened">
      <dialog class="c-navigation-selector__inner" v-click-outside="close">
        <h2 class="c-navigation-selector__title">{{ $t('navigation.select') }}</h2>
        <div class="c-navigation-selector__list">
          <a :href="'https://www.google.com/maps/dir/?api=1&destination='+address" target="_blank">
            <img src="../assets/picto/navigation/gmap.png" alt="">Google Maps
          </a>
          <a :href="'https://www.waze.com/ul?ll='+latLng+'&navigate=yes'" target="_blank">
            <img src="../assets/picto/navigation/waze.png" alt="">Waze
          </a>
          <a :href="'https://fr.mappy.com/itineraire#/vers/'+address" target="_blank">
            <img src="../assets/picto/navigation/mappy.png" alt="">Mappy
          </a>
          <a :href="'https://maps.apple.com/?daddr='+address" target="_blank">
            <img src="../assets/picto/navigation/maps.png" alt="">Plans
          </a>
          <a :href="'https://www.viamichelin.fr/itineraires?itinerary='+itinerary" target="_blank">
            <img src="../assets/picto/navigation/via-michelin.png" alt="">Via Michelin
          </a>
        </div>
        <a class="c-navigation-selector__copy" @click="copyAddress">
          <span v-if="copied">{{ $t('navigation.copied') }}</span>
          <span v-else>{{ $t('navigation.copy') }}</span>
        </a>
      </dialog>
    </section>
  </transition>
</template>

<script>

export default {
  data() {
    return{
      opened: false,
      marker: false,
      copied: false
    }
  },
  computed:{
    address(){
      return encodeURIComponent(this.marker.adresseComplete)
    },
    latLng(){
      return encodeURIComponent(this.marker.position[0]+','+this.marker.position[1])
    },
    itinerary(){
      return encodeURIComponent('{"l":"Votre+position+actuelle","isMyLoc":true}~{"t":2,"l":"'+this.marker.adresseComplete+'","c":{"lng":'+this.marker.position[1]+',"lat":'+this.marker.position[0]+'},"isArrival":true}')
    }
  },
  methods:{
    copyAddress(){
      navigator.clipboard.writeText(this.marker.adresseComplete);
      this.copied = true
      setTimeout(()=>{
        this.copied = false
      }, 2000)
    },
    open(marker){
      this.marker = marker
      this.opened = true
    },
    close(){
      this.marker = false
      this.opened = false
    }
  },
  mounted() {
    this.emitter.on('openNavigationSelector', this.open);
  }
}
</script>

<style lang="scss">
.c-navigation-selector{
  position: overlay(); background: rgba(255,255,255,0.7); backdrop-filter: blur(5px); z-index: 99999;
  display: flex; align-items: center; justify-content: center;
  &__inner{
    background: $c-background; border-radius: $space; padding: $space-m $space; box-shadow: $box-shadow; width: 40rem; grid-gap: $space-m;
    display: flex; flex-flow: column; align-items: center; border: 0;
  }
  &__title{
    font-weight: 500; font-size: $font-m; text-align: center; padding: 0 $space-l; display: flex; flex-flow: column;
    align-items: center;
    &:before{
      content: ''; width: 3rem; height: 3rem; background: url("../assets/picto/pin.svg") no-repeat center;
      background-size: contain; display: inline-block; margin-bottom: $space
    }
  }
  &__list{
    display: flex;; flex-flow: row; grid-gap: $space-s; flex-wrap: wrap; justify-content: center;
    a{
      background: $c-white; width:10rem; height:10rem; display: flex; align-items: center; justify-content: center;
      flex-shrink: 0; white-space: nowrap; cursor: pointer;
      flex-flow: column; grid-gap: $space-s; padding: $space; border-radius: $space;
      font-size: $font-xxs; font-weight: 500; transition: allow(box-shadow);
      &:hover{
        box-shadow: $box-shadow;
      }
      img{ height: 4rem }
    }
  }
  &__copy{ color: $c-grey; text-decoration: underline; cursor: pointer }
}
</style>
