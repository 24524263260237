const drag = {
    mounted(el) {

        el.dragStart = (ev) => { el.classList.add('pressing'); el.setPointerCapture(ev.pointerId); }
        el.dragEnd = (ev) => { el.classList.remove('pressing', 'dragging'); el.releasePointerCapture(ev.pointerId) };
        el.drag = (ev) => { el.classList.add('dragging'); el.hasPointerCapture(ev.pointerId) && (el.scrollLeft -= ev.movementX); }

        el.classList.add('draggable');

        el.addEventListener("pointerdown", el.dragStart);
        el.addEventListener("pointerup", el.dragEnd);
        el.addEventListener("pointermove", el.drag);
    },
    unmounted(el) {

        el.removeEventListener("pointerdown", el.dragStart);
        el.removeEventListener("pointerup", el.dragEnd);
        el.removeEventListener("pointermove", el.drag);
    }
};

const clickOutside = {
    mounted (el, binding) {

        el.clickOutsideEvent = (event)=> {

            if (!(el === event.target || el.contains(event.target)))
                binding.value(event);
        };

        document.body.addEventListener('mousedown', el.clickOutsideEvent)
    },
    unmounted(el) {

        document.body.removeEventListener('mousedown', el.clickOutsideEvent)
    }
}

export {drag, clickOutside}
