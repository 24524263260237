<template>
  <article class="c-store-summary" :style="'order:'+order">
    <h2 class="c-store-summary__title">{{ marker.nom }}</h2>
    <p class="c-store-summary__addresss" v-if="marker.displayAdresse">{{ marker.adresse }}</p>
    <a class="c-store-summary__link" v-else-if="marker.siteInternet" :href="marker.siteInternet" target="_blank">{{ marker.siteInternet.replace('https://','') }}</a>
    <router-link class="c-store-summary__more" :to="{name:'store', params:{id:marker.id}}">{{ $t('online.see') }}</router-link>
    <div class="c-store-summary__tags">
      <a class="c-store-summary__tag c-store-summary__tag--shoes"></a>
      <a class="c-store-summary__tag c-store-summary__tag--store"></a>
      <a class="c-store-summary__tag c-store-summary__tag--mailbox"></a>
    </div>
  </article>
</template>

<script>
export default {
  props:['order','marker'],
  methods:{
    showItem(){
      this.emitter.emit('showSidebar', {element:'store-details', id:1});
    }
  }
}
</script>

<style lang="scss">
.c-store-summary{
  display: flex; flex-flow: column; grid-gap: $space-s; position: relative;
  &+&{ border-top:1px solid $c-border; padding-top: $space }
  &__title{ @extend %title-main; padding-right: 7rem }
  &__tags{ display: flex; grid-gap: $space-s }
  &__tag{
    width: 3.5rem; height: 3.5rem; background: $c-white no-repeat center; background-size: 60%; border-radius: 50%; display: inline-block;
    &--shoes{ background-image: url("../assets/picto/shoes.svg"); background-color: $c-theme }
    &--store{ background-image: url("../assets/picto/store.svg") }
    &--mailbox{ background-image: url("../assets/picto/mailbox.svg") }
  }
  &__link{
    flex: 1; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: calc(100% - 8rem); cursor: pointer;
  }
  &__more{
    display: inline-flex; align-items: center; justify-content: flex-end; grid-gap: $space-s; position: overlay(); cursor: pointer;
    padding-bottom: $space-s;
    &:after{
      background: url("../assets/picto/arrow.svg") no-repeat center; background-size: cover; content: ''; width: 1em;
      height: 1em; display: inline-block;
    }
  }
}
</style>
