<template>
  <div class="c-online-solutions">
    <router-link class="c-online-solutions__close" :to="{name:'home'}"></router-link>
    <h2 class="c-online-solutions__title">{{ $t('online.title') }}</h2>
    <div class="c-online-solutions__items">
      <StoreSummary v-for="marker in solutions" :marker="marker" :key="marker.id"/>
    </div>
  </div>
</template>

<script>
import StoreSummary from "@/components/StoreSummary.vue";

export default {
  components: {
    StoreSummary
  },
  computed:{
    markers(){
      return this.$store.state.markers;
    },
    solutions(){
        return Object.values(this.markers).filter(marker=>{
          return !marker.displayAdresse
        })
    }
  }
}
</script>

<style lang="scss">
.c-online-solutions{
  display: flex; flex-flow: column; grid-gap: $space;
  &__items{
    display: flex; flex-flow: column; grid-gap: $space; padding-top: $space;
  }
  &__title{ @extend %title-section }
  &__close{
    background: url("../assets/picto/close.svg") no-repeat center; display: inline-block; width: 3.2rem; height: 3.2rem;
    position: absolute; right: $space-s; top: $space; background-size: contain; cursor: pointer;
  }
}
</style>
