import { createApp } from 'vue'
import { createI18n } from "vue-i18n";
import axios from 'axios'
import VueAxios from 'vue-axios'
import mitt from 'mitt';
import { createWebHistory, createRouter } from 'vue-router'

import fr from "../locales/fr.json";

import App from './App.vue'
import store from './scripts/store'
import {drag, clickOutside} from './scripts/directives'

const emitter = mitt();

const i18n = createI18n({
    locale: "fr",
    fallbackLocale: "fr",
    globalInjection: true,
    legacy: false,
    messages: { fr }
});

import OnlineSolutions from "@/components/OnlineSolutions.vue";
import SearchResults from "@/components/SearchResults.vue";
import StoreDetails from "@/components/StoreDetails.vue";

const routes = [
    { meta:{ depth:1 }, name:'home', path: '/' },
    { meta:{ depth:3 }, name:'online-solutions', path: '/online-solutions', component: OnlineSolutions },
    { meta:{ depth:2 }, name:'search', path: '/search/:term', component: SearchResults },
    { meta:{ depth:4 }, name:'store', path: '/store/:id', component: StoreDetails }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const app = createApp(App)

app.directive('drag', drag);
app.directive('clickOutside', clickOutside);
app.use(i18n)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.config.globalProperties.emitter = emitter;

app.mount('#root')
