<template>
  <ul class="c-solution-filters">
    <li v-for="id in activities" :key="id">
      <label class="c-solution-filters__item" :class="'c-solution-filters__item--'+id">
        <input type="checkbox" v-model="form.activities" :value="id"/><span>{{ $t('filters.list.'+id) }}</span>
      </label>
    </li>
    <li v-for="id in filters" :key="id">
      <label class="c-solution-filters__item">
        <input type="checkbox" v-model="form.filters" :value="id"/><span>{{ $t('filters.list.'+id) }}</span>
      </label>
    </li>
    <li>
      <router-link class="c-solution-filters__item c-solution-filters__item--online" :to="{name:'online-solutions'}">
        <span>{{ $t('filters.online') }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  watch:{
    form:{
      handler() {

          this.emitter.emit('filtersUpdated', this.form);
      },
      deep:true
    }
  },
  methods:{
    showOnlineSolutions(){

      this.emitter.emit('showSidebar', {element:'online-solutions'});
    }
  },
  data() {
    return{
      form: {
        activities:[],
        filters:[]
      },
      activities: [
        "shoes",
        "clothes"
      ],
      filters: [
        "stores",
        "services",
        "change"
      ]
    }
  }
}
</script>

<style lang="scss">
.c-solution-filters{
  height: 100%; display: flex; flex-shrink: 0; grid-gap: $space-s; align-items: center;
  &__item{
    position: relative; display: inline-block; cursor: pointer;
    &:hover span{
      box-shadow: $box-shadow; background: rgba(255,255,255,0.6);
    }
    input{ position: overlay(); opacity: 0; padding: 0; margin: 0 }
    input:checked+span{
      background: $c-white; padding-right: $space-m; font-weight: 500; color: inherit;
      &:after{ opacity: 1; transform: none }
    }
    &--shoes input:checked+span{ background: $c-theme }
    &--clothes input:checked+span{ background: $c-theme-alt }
    span{
      border: 1px solid; border-radius: $radius; display: inline-flex; align-items: center; padding: $space-xxs $space;
      background: rgba(255,255,255,0.1); backdrop-filter: blur(4px);
      transition: allow(background padding color box-shadow);
      &:after{
        content: '';background: url("../assets/picto/close.svg") no-repeat; background-size: contain; opacity: 0;
        width: 16px; height: 16px; display: inline-block; position: absolute; right: 1rem; top: 50%; margin-top: -8px;
        transition: allow(opacity transform); transform: scale(0);
      }
    }
    &--online{
      span{ background: $c-black; color: $c-white; border-color: $c-black; font-weight: 500 }
      &:hover span{ background: #222; border-color: #222 }
    }
  }
}
</style>
