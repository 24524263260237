<template>
  <header class="c-topbar">
    <SearchBox/>
    <SolutionFilters/>
  </header>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
import SolutionFilters from "@/components/SolutionFilters.vue";
export default {
  components: {
    SearchBox,
    SolutionFilters
  },
  data() {
    return{

    }
  }
}
</script>

<style lang="scss">
.c-topbar{
  position: absolute; left: $space-m; top: $space; right: $space-m; height: 4rem; z-index: 9999; display: flex;
  font-size: $font-xs; grid-gap: $space-s;
}
</style>
