<template>
  <div class="c-searchbox" :class="{'has-results':hasResults}" v-click-outside="reset">
    <form class="c-searchbox__form" @submit.prevent="search">
      <button type="submit" class="c-searchbox__form-submit"/>
      <input type="search" @focus="getFocus" ref="input" class="c-searchbox__form-input" :placeholder="$t('search.input')" v-model="query"/>
      <button class="c-searchbox__form-vocal" :class="{'is-active':recognizing}" @click.prevent="toggleRecognition" v-if="recognizer"/>
    </form>
    <div class="c-searchbox__results" v-if="hasResults">
      <div class="c-searchbox__results-group" :class="{'has-more':more.recent_search}" v-if="recent_search.length">
        <a class="c-searchbox__results-more" @click="moreResults('recent_search')" v-if="recent_search.length > 2">
          <span v-if="more.recent_search">{{ $t('search.less') }}</span>
          <span v-else>{{ $t('search.more') }}</span>
        </a>
        <div class="c-searchbox__results-title">{{ $t('search.recent') }}</div>
        <a class="c-searchbox__result" v-for="item in recent_search" :key="item.id" v-html="highlight(item.label)" @click="goTo(item)"></a>
      </div>
      <div class="c-searchbox__results-group" :class="{'has-more':more.search_results}" v-if="search_results.length">
        <a class="c-searchbox__results-more" @click="moreResults('search_results')" v-if="search_results.length > 2">
          <span v-if="more.search_results">{{ $t('search.less') }}</span>
          <span v-else>{{ $t('search.more') }}</span>
        </a>
        <div class="c-searchbox__results-title">{{ $t('search.suggest') }}</div>
        <a class="c-searchbox__result" v-for="item in search_results" :key="item.id" v-html="highlight(item.label)" @click="goTo(item)"></a>
      </div>
    </div>
  </div>
</template>

<script>

import {search} from "@/scripts/api";

export default {
  data() {
    return {
      recognizer: false,
      recognizing: false,
      timeout: false,
      transcription: '',
      query: '',
      has_focus: false,
      more: {
        search_results:false,
        recent_search:false
      },
      api_url: process.env.VUE_APP_API_URL+'/api/public/carte/geoloc',
    }
  },
  watch:{
    query(){
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.searchAutocomplete,400)
    }
  },
  computed:{
    markers(){
      return this.$store.state.markers
    },
    recent_search(){
      return this.$store.state.recent_search
    },
    search_results(){
      return this.$store.state.search_results
    },
    hasResults(){
      return this.has_focus && ( this.search_results.length || this.recent_search.length )
    }
  },
  methods:{
    getFocus(){
      this.has_focus=true
      this.$router.push({ name: 'home'})
    },
    setFocus(){
      this.$refs.input.focus()
    },
    search(){
      this.reset(true)
      this.$router.push({ name: 'search', params: { term: this.query } })
    },
    moreResults(type){
      this.more[type] = !this.more[type]
    },
    goTo(address){

      this.$store.commit('recent_search', address)
      this.reset()

      if( address.type === 'BOUTIQUES' )
        this.$router.push({ name: 'store', params: { id: address.id } })

      this.emitter.emit('goTo', address);
    },
    highlight(label){
      const regEx = new RegExp(this.query, "ig");
      return label.replace(regEx, (match) => {
        return '<b>' + match + '</b>';
      })
    },
    async searchAutocomplete(){

      const search_results = await search(this.query, 20);
      this.$store.commit('search_results', search_results);
    },
    initSpeechRecognition(){

      const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

      if( !speechRecognition )
        return;

      this.recognizer = new speechRecognition();

      this.recognizer.addEventListener('result', (event) => {

        let transcription = '';

        for (let i = event.resultIndex, len = event.results.length; i < len; i++)
          transcription += event.results[i][0].transcript;

        if( transcription.length )
          this.query = transcription;
      });

      this.recognizer.addEventListener('error', (event) => {

        this.recognizing = false;
        console.log(`Recognition error: ${event.message}`);
      });

      this.recognizer.addEventListener('end', () => {

        this.recognizing = false;
        console.log('Recognition ended');
      });
    },
    toggleRecognition(){

      if( this.recognizing )
        this.stopRecognition()
      else
        this.startRecognition()
    },
    startRecognition(){

      try {

        this.recognizing = true;
        this.recognizer.start();
        console.log('Recognition started');

      } catch (error) {

        this.recognizing = false;
        console.log(`Recognition error: ${error.message}`);
      }
    },
    stopRecognition(){

      this.recognizing = false;
      this.recognizer.stop();
    },
    reset(keep_query){

      if( this.$refs.input )
        this.$refs.input.blur()

      this.has_focus = false

      if( !keep_query || typeof keep_query == 'undefined')
        this.query = ""
    },
    watchEscapeKey(){

      document.addEventListener('keyup', (evt)=> {
        if (evt.key === "Escape")
          this.reset()
      });
    }
  },
  mounted() {

    this.initSpeechRecognition()
    this.watchEscapeKey()

    this.emitter.on('searchFocus', this.setFocus)
    this.$store.dispatch('recent_search');

    if( this.$route.name === 'search' && this.$route.params.term )
      this.query = this.$route.params.term;
  }
}
</script>

<style lang="scss">
.c-searchbox{
  height: 100%; flex-grow: 1; position: relative;
  &__form{
    height: 100%; background: $c-white; border-radius: $radius; display: flex; position: relative; z-index: 2;
    padding: 0 $space-s; box-shadow: $box-shadow;
    .has-results &{ box-shadow: none }
    &-input{ height: 100%; border: 0; flex-grow: 1; background: none }
    &-submit{
      background: url("../assets/picto/magnifier.svg") no-repeat center; background-size: 50%; aspect-ratio: 1;
      height: 100%;
    }
    &-vocal{
      background: url("../assets/picto/microphone.svg") no-repeat center; background-size: 50%; aspect-ratio: 1;
      height: 100%; cursor: pointer; position: relative;
      &.is-active{
        background: none; animation: pulse 3s $ease-in-out-quad infinite;
        &:after{
          content: ''; background: #cc0000; width: 1rem; height: 1rem; position: absolute;
          left: 50%; top: 50%; margin-left: -0.5rem; margin-top: -0.5rem; border-radius: 50%;
        }
        &:before{
          content: ''; outline: 1px solid #cc0000; outline-offset: 3px; width: 1rem; height: 1rem; position: absolute;
          left: 50%; top: 50%; margin-left: -0.5rem; margin-top: -0.5rem; border-radius: 50%;
        }
      }
    }
  }
  &__results{
    position: absolute; top: 100%; width: 100%; background: $c-white; display: flex; flex-flow: column;
    margin-top: -$radius/2; z-index: 1; padding: 0 $space; padding-top: $radius; border-radius: 0 0 $radius $radius;
    padding-bottom: $space-m; box-shadow: $box-shadow; grid-gap: $space-m;
    &-group{
      display: flex; flex-flow: column; position: relative; max-height: 9rem; transition: allow(max-height); overflow: hidden;
      &.has-more{
        max-height: 20rem;
        a:after{ transform: scaleY(-1) }
      }
    }
    &-more{
      position: absolute; right: 0; top: 0; color: $c-grey; cursor: pointer;
      &:after{
        content: ''; width: 12px; height: 8px; background: url("../assets/picto/arrow-down.svg") no-repeat center;
        background-size: contain; display: inline-block; margin-left: $space-s; transition: allow(transform);
      }
    }
    &-title{
      font-size: $font-m; font-weight: 600; margin-bottom: $space-s;
    }
  }
  &__result{
    padding: $space-s 0; cursor: pointer; transition: allow(color);
    &:hover{ color: $c-theme }
    b{ font-weight: 600 }
    &+&{ border-top: 1px solid $c-border }
  }
}

@keyframes pulse {
  0%{ opacity: 1 }
  50%{ opacity: 0.3 }
  100%{ opacity: 1 }
}
</style>
